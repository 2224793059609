<template>
	<section>
		<v-img
			v-if="isAdmin"
			max-width="20"
			class="ml-1 d-inline-block icon-verified"
			src="../icons/verified_use_fill.png"
		/>
		<v-chip
			v-if="isNotice"
			class="ml-1"
			color="#E6F2FF"
			text-color="#0063CC"
			small
			>공지</v-chip
		>
		<v-chip
			v-if="isPdPick"
			class="ml-1"
			color="#FDF2F6"
			text-color="#EE1F60"
			small
			>PD pick!</v-chip
		>
		<v-chip
			v-if="isAuthor(author)"
			class="ml-1"
			color="#F5F6FA"
			text-color="#76768E"
			small
			>나</v-chip
		>
	</section>
</template>

<script>
export default {
	name: "Badge",
	props: {
		commentAdminFlag: String,
		kind: String,
		author: String,
	},
	computed: {
		isAdmin() {
			return this.commentAdminFlag == "Y";
		},
		isPdPick() {
			return this.kind == "PDPick";
		},
		isNotice() {
			return this.kind == "Notice";
		},
	},
};
</script>

<style>
.icon-verified {
	vertical-align: middle;
}
</style>